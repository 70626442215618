import React, { useState, ReactNode } from 'react'
import { TextField, Button, Box } from '@mui/material'
import { postJson } from '@utils/api'
import { jakToast } from '@utils/index'
import { useAdminAuth } from 'context/AdminAuthContext'

interface WithAuthProps {
  children?: ReactNode
}

const withAuth = (
  WrappedComponent: React.ComponentType<WithAuthProps>
): React.FC<WithAuthProps> => {
  const WithAuth: React.FC<WithAuthProps> = ({ children }) => {
    const [password, setPassword] = useState('')
    const { isAuthenticated, setIsAuthenticated } = useAdminAuth()

    const handlePasswordChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setPassword(event.target.value)
    }

    const handleSubmitPassword = async (
      e: React.FormEvent<HTMLFormElement>
    ) => {
      e.preventDefault()
      const res = await postJson('/public/new-owner-invite-access', {
        password,
      })
      if (res.ok) {
        setIsAuthenticated(true)
        jakToast('Correct password, access granted')
      } else {
        jakToast('Incorrect password, please try again')
      }
    }

    const renderAuthForm = () => (
      <form onSubmit={handleSubmitPassword}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            height: '100vh',
            margin: 'auto',
            alignItems: 'center',
          }}
        >
          <TextField
            label="Enter password"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </form>
    )

    return (
      <Box>
        {isAuthenticated ? (
          <WrappedComponent>{children}</WrappedComponent>
        ) : (
          renderAuthForm()
        )}
      </Box>
    )
  }

  return WithAuth
}

export default withAuth
