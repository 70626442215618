import * as React from 'react'
import { produce } from 'immer'

export enum ActionType {
  USER_LOGIN = 'USER_LOGIN',
  UPDATE_USER = 'UPDATE_USER',
}

interface UserLoginAction {
  type: ActionType.USER_LOGIN
  payload: User | null
}

interface UpdateUserAction {
  type: ActionType.UPDATE_USER
  payload: Partial<User>
}

interface GlobalState {
  user: User | null
}

type Action = UserLoginAction | UpdateUserAction

const initialState: GlobalState = {
  user: null,
}

const globalReducer = produce((draft: GlobalState, action: Action): void => {
  switch (action.type) {
    case ActionType.USER_LOGIN:
      draft.user = action.payload
      break
    case ActionType.UPDATE_USER:
      // eslint-disable-next-line max-depth
      if (draft.user) {
        Object.assign(draft.user, action.payload)
      }
      break
  }
})

interface GlobalContextType {
  state: GlobalState
  dispatch: React.Dispatch<Action>
}

export const GlobalStateContext = React.createContext<GlobalContextType>({
  state: initialState,
  dispatch: () => {},
})

interface GlobalStateProviderProps {
  children: React.ReactNode
}

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(globalReducer, initialState)

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider
