import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import {
  People as PeopleIcon,
  Article as ArticleIcon,
  DocumentScanner,
  DesktopMac,
  Calculate,
} from '@mui/icons-material'
import withAuth from './withAuth'

type AdminButton = {
  title: string
  path: string
  icon: JSX.Element
}

const AdminDashboard: FC = () => {
  const navigate = useNavigate()

  const adminButtons: AdminButton[] = [
    {
      title: 'Policy Overrides',
      path: '/admin/policy-overrides',
      icon: <DocumentScanner />,
    },
    {
      title: 'Template Variables',
      path: '/admin/template-variables',
      icon: <ArticleIcon />,
    },
    {
      title: 'New Owner Invite  ',
      path: '/admin/new-owner-invite',
      icon: <PeopleIcon />,
    },
    {
      title: 'Prod Demo',
      path: '/admin/prod-demo',
      icon: <DesktopMac />,
    },
    {
      title: 'Price Calculator',
      path: '/admin/price-calculator',
      icon: <Calculate />,
    },
  ]

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Typography variant="h4" textAlign="center" mb={4} color="primary.800">
          Admin Dashboard
        </Typography>
        <Grid container spacing={3}>
          {adminButtons.map(({ title, path, icon }) => (
            <Grid item xs={12} sm={6} key={path}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={() => navigate(path)}
                sx={{
                  py: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {icon}
                {title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

const AdminDashboardWithAuth = withAuth(AdminDashboard)

export default AdminDashboardWithAuth
