export enum Page {
  Home = 'home',
  ActionItems = 'action-items',
  Insights = 'insights',
  TerritoryInfo = 'territory-info',
  Billing = 'billing',
  Locations = 'locations',
  Settings = 'settings',
  Permissions = 'permissions',
}

export enum Role {
  user = 'user',
  admin = 'admin',
  owner = 'owner',
}
