import { getAuth } from 'firebase/auth'

const getAuthToken = async () => {
  const auth = getAuth()
  if (auth.currentUser) {
    return await auth.currentUser.getIdToken()
  }
  return null
}

const _getApiEndpoint = (endpoint: string) => {
  if (import.meta.env.DEV) {
    return `http://localhost:3001${endpoint}`
  }
  return `${import.meta.env.VITE_JAKIRO_API_URL}${endpoint}`
}

const apiFetch = async (endpoint: string, options: RequestInit = {}) => {
  const token = await getAuthToken()
  const headers = new Headers(options.headers || {})
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const config = {
    ...options,
    headers,
    credentials: 'include' as RequestCredentials,
  }
  if (import.meta.env.DEV) {
    console.log(_getApiEndpoint(endpoint), config)
  }
  const response = await fetch(_getApiEndpoint(endpoint), config)

  return response
}

export const get = (endpoint: string, options?: RequestInit) =>
  apiFetch(endpoint, { ...options, method: 'GET' })

export const post = (endpoint: string, body: unknown, options?: RequestInit) =>
  apiFetch(endpoint, { ...options, method: 'POST', body: JSON.stringify(body) })

export const postJson = (
  endpoint: string,
  body: Record<string, unknown>,
  options?: RequestInit
) =>
  apiFetch(endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const postFormData = (
  endpoint: string,
  formData: FormData,
  options?: RequestInit
) => {
  return apiFetch(endpoint, {
    ...options,
    method: 'POST',
    body: formData,
    headers: {
      ...options?.headers,
    },
  })
}

export const put = (endpoint: string, body: unknown, options?: RequestInit) =>
  apiFetch(endpoint, { ...options, method: 'PUT', body: JSON.stringify(body) })

export const putJson = (
  endpoint: string,
  body: Record<string, unknown>,
  options?: RequestInit
) =>
  apiFetch(endpoint, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const del = (endpoint: string, options?: RequestInit) =>
  apiFetch(endpoint, { ...options, method: 'DELETE' })

export const delJson = (
  endpoint: string,
  body: Record<string, unknown>,
  options?: RequestInit
) =>
  apiFetch(endpoint, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })
