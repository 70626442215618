import { FC, useEffect, useRef, useState } from 'react'
import {
  Card,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  AppBar,
  Toolbar,
  CircularProgress,
} from '@mui/material'
import withAuth from './withAuth'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { produce } from 'immer'
import AddIcon from '@mui/icons-material/Add'
import { get, postJson } from 'utils/api'
import { CheckCircle, ErrorOutline, Save } from '@mui/icons-material'

type FranchiseeOverride = {
  placeholder: string
  content: string
}

type TemplateVariable = {
  placeholder: string
  defaultContent: string
  franchiseeOverrides: Record<string, FranchiseeOverride>
}

type CompanyWithFranchisees = {
  name: string
  tag: string
  franchisees: Franchisee[]
}

type Franchisee = {
  owner: string
  territory: string
  franchiseeId: string
}

const OverrideCard: FC<{
  franchiseeId: string
  franchisees: Franchisee[]
  onChangeFranchiseeContent: (franchiseeId: string, content: string) => void
  onDeleteOverride: (franchiseeId: string) => void
  content: string
}> = ({
  franchiseeId,
  franchisees,
  onChangeFranchiseeContent,
  onDeleteOverride,
  content,
}) => (
  <Card sx={{ p: 2, mb: 2, backgroundColor: '#fefefe' }} variant="outlined">
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}
    >
      <Select
        value={franchiseeId}
        onChange={(e) =>
          onChangeFranchiseeContent(e.target.value as string, content)
        }
        fullWidth
      >
        {franchisees.map((f) => (
          <MenuItem key={f.franchiseeId} value={f.franchiseeId}>
            {`${f.owner} - ${f.territory} (fid: ${f.franchiseeId.slice(0, 4)})`}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={() => onDeleteOverride(franchiseeId)} size="small">
        <DeleteIcon />
      </IconButton>
    </Box>
    <TextField
      multiline
      rows={4}
      value={content}
      onChange={(e) => onChangeFranchiseeContent(franchiseeId, e.target.value)}
      fullWidth
    />
  </Card>
)

const VariableCard: FC<{
  variable: TemplateVariable
  index: number
  franchisees: Franchisee[]
  onChangePlaceholder: (index: number, value: string) => void
  onChangeDefaultContent: (index: number, value: string) => void
  onChangeFranchiseeContent: (
    index: number,
    franchiseeId: string,
    content: string
  ) => void
  onDeleteOverride: (index: number, franchiseeId: string) => void
  onDeleteVariable: (index: number) => void
  onAddOverride: (index: number) => void
}> = ({ variable, index, ...props }) => (
  <Card sx={{ backgroundColor: 'hsl(185, 30%, 94%)', p: 4, mb: 4 }}>
    <Box sx={{ position: 'relative' }}>
      <TextField
        label="Placeholder Key"
        helperText="e.g. <replace_me_with_class_names>"
        value={variable.placeholder}
        onChange={(e) => props.onChangePlaceholder(index, e.target.value)}
        fullWidth
        sx={{ mb: 3, '& .MuiInputBase-root': { backgroundColor: 'white' } }}
      />
      <IconButton
        onClick={() => props.onDeleteVariable(index)}
        size="small"
        sx={{ position: 'absolute', top: 10, right: 10 }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Default Content
      </Typography>
      <TextField
        value={variable.defaultContent}
        onChange={(e) => props.onChangeDefaultContent(index, e.target.value)}
        multiline
        rows={4}
        fullWidth
      />
    </Card>

    <Typography variant="subtitle1" sx={{ mb: 2 }}>
      Franchisee Overrides
    </Typography>
    {Object.entries(variable.franchiseeOverrides).map(
      ([franchiseeId, override]) => (
        <OverrideCard
          key={franchiseeId}
          franchiseeId={franchiseeId}
          franchisees={props.franchisees}
          content={override.content}
          onChangeFranchiseeContent={(fId, content) =>
            props.onChangeFranchiseeContent(index, fId, content)
          }
          onDeleteOverride={(fId) => props.onDeleteOverride(index, fId)}
        />
      )
    )}

    <Button
      startIcon={<AddIcon />}
      onClick={() => props.onAddOverride(index)}
      sx={{ mt: 2 }}
    >
      Add Franchisee Override
    </Button>
  </Card>
)

const EditTemplateVariablesComponent: FC = () => {
  const [allCompanies, setAllCompanies] = useState<CompanyWithFranchisees[]>([])
  const [selectedCompany, setSelectedCompany] = useState<string>('')
  const [variables, setVariables] = useState<TemplateVariable[]>([])
  const [saveState, setSaveState] = useState<
    'unsaved' | 'saving' | 'saved' | 'error'
  >('saved')

  const debounceRef = useRef<NodeJS.Timeout | null>(null)
  const previousVariablesRef = useRef<TemplateVariable[]>([])

  const handleCompanyChange = async (tag: string): Promise<void> => {
    setSelectedCompany(tag)
    const res = await get(`/dashboard/admin/template-variables/${tag}`)
    const data = await res.json()
    setVariables(data.variables || [])
  }

  useEffect(() => {
    const fetchCompanies = async (): Promise<void> => {
      const res = await get('/dashboard/admin/template-variables/companies')
      const data = await res.json()
      setAllCompanies(data)
    }
    fetchCompanies()
  }, [])

  useEffect(() => {
    if (variables.length > 0) {
      setSaveState('unsaved')
    }
  }, [variables])

  const getDeletedItems = (
    current: TemplateVariable[],
    previous: TemplateVariable[]
  ): {
    deletedOverrides: Record<string, string>
    deletedVariables: string[]
  } => {
    const deletedOverrides: Record<string, string> = {}
    const deletedVariables: string[] = []

    previous.forEach((prevVariable) => {
      const currentVariable = current.find(
        (v) => v.placeholder === prevVariable.placeholder
      )

      if (!currentVariable) {
        deletedVariables.push(prevVariable.placeholder)
        return
      }

      Object.entries(prevVariable.franchiseeOverrides).forEach(
        ([franchiseeId, override]) => {
          if (!currentVariable.franchiseeOverrides[franchiseeId]) {
            deletedOverrides[franchiseeId] = override.placeholder
          }
        }
      )
    })

    return { deletedOverrides, deletedVariables }
  }

  useEffect(() => {
    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current)
    }
  }, [variables, selectedCompany])

  const handleAddVariable = (): void => {
    setVariables(
      produce((draft) => {
        draft.push({
          placeholder: '',
          defaultContent: '',
          franchiseeOverrides: {},
        })
      })
    )
  }

  const handleChangePlaceholder = (index: number, value: string): void => {
    setVariables(
      produce((draft) => {
        draft[index].placeholder = value
      })
    )
  }

  const handleChangeDefaultContent = (index: number, value: string): void => {
    setVariables(
      produce((draft) => {
        draft[index].defaultContent = value
      })
    )
  }

  const handleChangeFranchiseeContent = (
    index: number,
    franchiseeId: string,
    content: string
  ): void => {
    setVariables(
      produce((draft) => {
        if (draft[index].franchiseeOverrides['new-id']) {
          delete draft[index].franchiseeOverrides['new-id']
        }
        draft[index].franchiseeOverrides[franchiseeId] = {
          placeholder: draft[index].placeholder,
          content,
        }
      })
    )
  }

  const handleDeleteVariable = (index: number): void => {
    setVariables(
      produce((draft) => {
        draft.splice(index, 1)
      })
    )
  }

  const handleDeleteOverride = (index: number, franchiseeId: string): void => {
    setVariables(
      produce((draft) => {
        delete draft[index].franchiseeOverrides[franchiseeId]
      })
    )
  }

  const handleAddOverride = (index: number): void => {
    setVariables(
      produce((draft) => {
        draft[index].franchiseeOverrides['new-id'] = {
          placeholder: draft[index].placeholder,
          content: '',
        }
      })
    )
  }

  const handleSave = (): void => {
    const shouldSkipSave = variables.some(
      (v) => 'new-id' in v.franchiseeOverrides || v.placeholder === ''
    )

    if (shouldSkipSave || !selectedCompany) {
      return undefined
    }

    const { deletedOverrides, deletedVariables } = getDeletedItems(
      variables,
      previousVariablesRef.current
    )
    previousVariablesRef.current = structuredClone(variables)

    if (
      Object.keys(deletedOverrides).length === 0 &&
      deletedVariables.length === 0 &&
      debounceRef.current
    ) {
      clearTimeout(debounceRef.current)
    }

    setSaveState('saving')
    const data = {
      tag: selectedCompany,
      variables,
      deletedOverrides,
      deletedVariables,
    }
    postJson('/dashboard/admin/template-variables/', data)
      .then(() => setSaveState('saved'))
      .catch(() => setSaveState('error'))
  }

  if (!allCompanies || allCompanies.length === 0) return null

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <AppBar
        position="sticky"
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          opacity: 1,
          color: 'primary.700',
          backdropFilter: 'blur(10px)',
          background: 'none',
          height: '60px',
          boxShadow:
            '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h5" color="primary.700">
            Template Variables Editor
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" onClick={handleSave} sx={{ mr: 2 }}>
              <Save />
            </Button>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: saveState === 'error' ? 'error.main' : 'grey.500',
              }}
            >
              {saveState === 'saving' && (
                <>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  Saving...
                </>
              )}
              {saveState === 'error' && (
                <>
                  <ErrorOutline sx={{ mr: 1 }} />
                  Error saving!
                </>
              )}
              {saveState === 'saved' && (
                <>
                  <CheckCircle sx={{ mr: 1 }} />
                  Saved!
                </>
              )}
              {saveState === 'unsaved' && (
                <>
                  <ErrorOutline sx={{ mr: 1 }} />
                  Unsaved
                </>
              )}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 4, position: 'sticky', top: '70px', zIndex: 5000 }}>
        <FormControl
          fullWidth
          sx={{
            backgroundColor: '#fefefe',
          }}
        >
          <InputLabel>Company</InputLabel>
          <Select
            value={selectedCompany}
            onChange={(e) => handleCompanyChange(e.target.value as string)}
            sx={{
              backgroundColor: 'white',
            }}
            label="Company"
          >
            {allCompanies.map((c) => (
              <MenuItem key={c.tag} value={c.tag}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          px: 4,
        }}
      >
        {variables.map((variable, index) => (
          <VariableCard
            key={index}
            variable={variable}
            index={index}
            franchisees={
              allCompanies.find((c) => c.tag === selectedCompany)
                ?.franchisees || []
            }
            onChangePlaceholder={handleChangePlaceholder}
            onChangeDefaultContent={handleChangeDefaultContent}
            onChangeFranchiseeContent={handleChangeFranchiseeContent}
            onDeleteVariable={handleDeleteVariable}
            onDeleteOverride={handleDeleteOverride}
            onAddOverride={handleAddOverride}
          />
        ))}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddVariable}
          sx={{ my: 2 }}
        >
          Add New Template Variable
        </Button>
      </Box>
    </Box>
  )
}

const EditTemplateVariables = withAuth(EditTemplateVariablesComponent)

export default EditTemplateVariables
