import { FirebaseApp, initializeApp, getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore/lite'
import { ToastOptions, cssTransition, toast } from 'react-toastify'
import { useContext } from 'react'
import { GlobalStateContext } from '../context/GlobalStateProvider'
import { Page, Role } from '../constants'
import { postJson } from './api'
import theme from 'constants/theme'
import { GlobalFormState } from '@components/shared/TerritoryInfo'

export const initFirebase = () => {
  const env = import.meta.env.DEV ? 'DEV' : 'PROD'
  const firebaseConfig = {
    apiKey: import.meta.env[`VITE_FIREBASE_${env}_API_KEY`],
    authDomain: import.meta.env[`VITE_FIREBASE_${env}_AUTH_DOMAIN`],
    projectId: import.meta.env[`VITE_FIREBASE_${env}_PROJECT_ID`],
    storageBucket: import.meta.env[`VITE_FIREBASE_${env}_STORAGE_BUCKET`],
    messagingSenderId: import.meta.env[
      `VITE_FIREBASE_${env}_MESSAGING_SENDER_ID`
    ],
    appId: import.meta.env[`VITE_FIREBASE_${env}_APP_ID`],
    measurementId: import.meta.env[`VITE_FIREBASE_${env}_MEASUREMENT_ID`],
  }
  return initializeApp(firebaseConfig)
}

export const getFirebaseApp = (): FirebaseApp => {
  return getApp()
}

export const fetchUserData = async (uid: string): Promise<User | null> => {
  const db = getFirestore(getFirebaseApp())
  const docRef = doc(db, `users/${uid}`)
  const snapshot = await getDoc(docRef)

  if (!snapshot.exists() || !snapshot.data()) {
    console.log(`No user found with the uid ${uid}`)
    return null
  }

  return snapshot.data() as User
}

export const persistTerritoryInfo = async (formState: GlobalFormState) => {
  const app = getFirebaseApp()
  const db = getFirestore(app)
  const getIdTokenResult = async () => {
    const auth = getAuth(app)
    const user = auth.currentUser
    if (!user) throw new Error('No user is currently signed in')
    return await user.getIdTokenResult(true)
  }

  const idTokenResult = await getIdTokenResult()

  const franchiseeId = idTokenResult.claims.franchiseeId as string | undefined

  if (!franchiseeId) {
    throw new Error('User does not have a franchiseeId claim')
  }

  const ref = doc(db, 'franchisees', franchiseeId)
  await updateDoc(ref, {
    territoryInfo: formState,
  })
}

export const jakToast = (msg: string, options: ToastOptions = {}) => {
  const fadeInOut = cssTransition({
    enter: 'fade-in-bottom',
    exit: 'fade-out-right',
  })
  toast(msg, {
    autoClose: 2000,
    style: { borderRadius: '10px', fontFamily: theme.typography.fontFamily },
    type: options.type || 'success',
    icon: false,
    progressStyle: {
      backgroundColor:
        options.type === 'error'
          ? theme.palette.error.main
          : theme.palette.primary.main,
    },
    transition: fadeInOut,
    ...options,
  })
}

export const fetchIsEnabled = async (uid: string): Promise<boolean> => {
  if (!uid) {
    return false
  }
  const db = getFirestore(getFirebaseApp())
  const docRef = doc(db, 'users', uid)
  const snapshot = await getDoc(docRef)
  return snapshot.data()?.enabled ?? true
}

export const fetchUser = async () => {
  return getAuth(getFirebaseApp()).currentUser
}

export const logout = async () => {
  const auth = getAuth(getFirebaseApp())
  try {
    await auth.signOut()
    localStorage.removeItem('franchiseeId')
  } catch (error) {
    console.error('Error during force logout:', error)
  }
}

/* -------------------------- Hooks -------------------------- */

export const useRole = () => {
  const { state } = useContext(GlobalStateContext)
  const userRole = state.user?.role || 'user'

  const hasRole = (...roles: Role[]) => roles.includes(userRole)

  return { hasRole }
}

export const fetchHasPageAccess = async (page: Page): Promise<boolean> => {
  const res = await postJson('/dashboard/auth/single-page-access', { page })
  return res.ok
}
