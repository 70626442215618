import { FC, ComponentType, ReactNode, Suspense } from 'react'
import { AdminAuthProvider } from 'context/AdminAuthContext'
import { Navigate } from 'react-router-dom'
import { useAdminAuth } from 'context/AdminAuthContext'

interface ProtectedRouteProps {
  children: ReactNode
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAdminAuth()

  if (!isAuthenticated) {
    return <Navigate to="/admin" replace />
  }

  return <>{children}</>
}

export const withAdminAuth = <P extends object>(
  Component: ComponentType<P>
): FC<P> => {
  return (props) => (
    <AdminAuthProvider>
      <ProtectedRoute>
        <Suspense fallback={<div>Loading...</div>}>
          <Component {...props} />
        </Suspense>
      </ProtectedRoute>
    </AdminAuthProvider>
  )
}
