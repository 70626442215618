const Sizes = deepFreeze({
  Spacing: {
    Px: {
      Four: 4,
      Eight: 8,
      Twelve: 12,
      Sixteen: 16,
      TwentyFour: 24,
      ThirtyTwo: 32,
      FortyEight: 48,
      SixtyFour: 64,
      NinetySix: 96,
      OneHundredTwentyEight: 128,
      OneHundredNinetyTwo: 192,
      TwoHundredFiftySix: 256,
      ThreeHundredEightyFour: 384,
      FiveHundredTwelve: 512,
      SixHundredForty: 640,
      SevenHundredSixtyEight: 768,
    },
    Theme: {
      Half: 0.5,
      One: 1,
      OnePointFive: 1.5,
      Two: 2,
      Three: 3,
      Four: 4,
      Six: 6,
      Eight: 8,
      Twelve: 12,
      Sixteen: 16,
      TwentyFour: 24,
      ThirtyTwo: 32,
      FortyEight: 48,
      SixtyFour: 64,
      Eighty: 80,
      NinetySix: 96,
    },
  },
  Font: {
    Weight: {
      FourHundred: 400,
      FiveHundred: 500,
      SixHundred: 600,
      SevenHundred: 700,
    },
    XS: { fontSize: '12px', lineHeight: '16px' },
    SM: { fontSize: '14px', lineHeight: '20px' },
    Base: { fontSize: '16px', lineHeight: '24px' },
    LG: { fontSize: '18px', lineHeight: '28px' },
    XL: { fontSize: '20px', lineHeight: '28px' },
    '2XL': { fontSize: '24px', lineHeight: '32px' },
    '3XL': { fontSize: '30px', lineHeight: '36px' },
    '4XL': { fontSize: '36px', lineHeight: '40px' },
    '5XL': { fontSize: '48px', lineHeight: '1' },
    '6XL': { fontSize: '60px', lineHeight: '1' },
    '7XL': { fontSize: '72px', lineHeight: '1' },
  },
})

function deepFreeze<T>(obj: T) {
  const propNames = Object.getOwnPropertyNames(obj)
  for (const name of propNames) {
    const value = (obj as any)[name]
    if (value && typeof value === 'object') {
      deepFreeze(value)
    }
  }
  return Object.freeze(obj)
}

const ThemeSpacing = Sizes.Spacing.Theme
const PxSpacing = Sizes.Spacing.Px

export { Sizes, ThemeSpacing, PxSpacing }
