import React, { useState, useEffect } from 'react'
import {
  Select,
  Box,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Card,
  CircularProgress,
  TextField,
} from '@mui/material'
import { get, postJson } from '@utils/api'
import { Save } from '@mui/icons-material'

interface Franchisee {
  name: string
  id: string
  priceCalculator: string
}

interface Company {
  name: string
  id: string
  priceCalculators: { name: string; description: string }[]
}

const PriceCalculator: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
  const [franchisees, setFranchisees] = useState<Record<string, Franchisee>>({})
  const [selectedFranchiseeId, setSelectedFranchiseeId] = useState<string>('')
  useState<Franchisee | null>(null)
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [resultText, setResultText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [membershipFeeAmounts, setMembershipFeeAmounts] = useState<string>('')

  useEffect(() => {
    const fetchCompanies = async (): Promise<void> => {
      const response = await get('/dashboard/admin/companies')
      const companiesData = await response.json()
      setCompanies(companiesData)
    }
    fetchCompanies()
  }, [])

  const handleCompanyChange = (event: SelectChangeEvent<string>): void => {
    fetchFranchisees(event.target.value)
    setSelectedCompany(
      companies.find((co) => co.id === event.target.value) as Company
    )
  }

  const fetchFranchisees = async (companyId: string): Promise<void> => {
    const response = await get(
      `/dashboard/admin/franchisees?companyId=${companyId}`
    )
    const franchiseesData = await response.json()
    const hydratedFranchisees = hydrateState(franchiseesData)
    setFranchisees(hydratedFranchisees)
  }

  const hydrateState = (docs: Franchisee[]): Record<string, Franchisee> => {
    return docs.reduce((acc, doc) => {
      const name = extractCompanyName(doc)
      acc[doc.id] = {
        name: `${name} (${doc.id.slice(0, 4)})`,
        id: doc.id,
        priceCalculator: doc.priceCalculator,
      }
      return acc
    }, {} as Record<string, Franchisee>)
  }

  const extractCompanyName = (data: any): string => {
    return data?.territoryInfo?.contactInfo?.companyName ?? '[company name n/a]'
  }

  const handleFranchiseeChange = (event: SelectChangeEvent<string>): void => {
    setSelectedFranchiseeId(event.target.value)
  }

  const handleOptionChange = (event: SelectChangeEvent<string>): void => {
    setSelectedOption(event.target.value)
  }

  const handleAssignCalculator = (): void => {
    if (!selectedFranchiseeId || !selectedOption) {
      return
    }
    setLoading(true)
    postJson(
      `/dashboard/admin/franchisees/${selectedFranchiseeId}/priceCalculator`,
      {
        priceCalculator: selectedOption,
        membershipFeeAmounts,
      }
    )
      .then(() => {
        setResultText('Saved successfully')
        setFranchisees((prev) => ({
          ...prev,
          [selectedFranchiseeId]: {
            ...prev[selectedFranchiseeId],
            priceCalculator: selectedOption,
          },
        }))
        setLoading(false)
      })
      .catch((error) => {
        setResultText(`Error saving: ${error}`)
        setLoading(false)
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        height: '100vh',
        width: '600px',
        margin: 'auto',
        alignItems: 'center',
      }}
    >
      <Select
        value={selectedCompany?.id ?? ''}
        onChange={handleCompanyChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">Select Company</MenuItem>
        {Object.values(companies).map((company) => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
      {selectedCompany && (
        <TableContainer
          component={Card}
          sx={{ marginY: 2, backgroundColor: 'grey.100' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography variant="body1">Pricing Calculators</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCompany.priceCalculators.map((calculator) => (
                <TableRow key={calculator.name}>
                  <TableCell>
                    <code>{calculator.name}</code>
                  </TableCell>
                  <TableCell>{calculator.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Select
        value={selectedFranchiseeId}
        onChange={handleFranchiseeChange}
        displayEmpty
        fullWidth
        disabled={!selectedCompany}
      >
        <MenuItem value="">Select Franchisee</MenuItem>
        {Object.values(franchisees).map((franchisee) => (
          <MenuItem key={franchisee.id} value={franchisee.id}>
            {franchisee.name}
          </MenuItem>
        ))}
      </Select>

      {selectedFranchiseeId && (
        <Typography variant="body1" color="grey.600">
          Current Franchisee Calc:&nbsp;&nbsp;&nbsp;
          <code>
            {franchisees[selectedFranchiseeId].priceCalculator ?? 'None'}
          </code>
        </Typography>
      )}
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        displayEmpty
        fullWidth
        disabled={!selectedFranchiseeId}
      >
        <MenuItem value="">Select Option</MenuItem>
        {selectedCompany?.priceCalculators.map((calculator) => (
          <MenuItem key={calculator.name} value={calculator.name}>
            {calculator.name}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Membership Fee Amounts (student, student, ...)"
        fullWidth
        helperText="e.g. 100, 150, 200"
        value={membershipFeeAmounts}
        onChange={(e) => setMembershipFeeAmounts(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={handleAssignCalculator}
        disabled={loading}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : <Save />
        }
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
      <Typography variant="body1" color="grey.500">
        {resultText}
      </Typography>
    </Box>
  )
}

export default PriceCalculator
