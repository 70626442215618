import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirebaseApp, fetchUserData } from '.'
import { User as FirebaseUser } from 'firebase/auth'

export const fetchIsOnboarded = async () => {
  const app = getFirebaseApp()
  const currentUser = getAuth(app).currentUser
  if (!currentUser) {
    throw new Error('No current user found')
  }
  const userData = await fetchUserData(currentUser.uid)
  return !!userData?.isOnboarded
}

export const fetchIsLoggedIn = async (): Promise<FirebaseUser | null> => {
  const app = getFirebaseApp()
  const auth = getAuth(app)
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async (user) => {
      resolve(user)
    })
  })
}
